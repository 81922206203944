<template>
  
  <div class="line-vert _rExl" v-if="__rightExtraW"></div> 
  <div class="_frame _rEx" :style="cssRight" v-if="__rightExtraW">
    <div class="_rightglass">
      <div class="doorBorder">
        <div class="left"></div>
        <div class="right"></div>
      </div>
      <img :src="glass" class="_glass" v-if="glass">
    </div>
  </div>
</template>

<script>
import Configure from '@/services/config.service';
export default {
  name: 'RightExtra-Render',
  data() {
    return {
      APIhost: Configure.$HOST,
    }
  },

  props: {
    size: Object,
    rama: Object,
    glass: String
  },

  computed: {

    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },

    __Glass() {
      return this.$store.state.configurate[this.produktType].accesories.textureWindow.value
    },

    __rightExtraW() {
      if (this.$store.state.configurate[this.produktType].build.rightExtra.value > 0) {
        return this.$store.state.configurate[this.produktType].build.rightExtra.value
      }
      return 0
    },

    __rightExtraH() {
      return this.$store.state.configurate[this.produktType].build.heightDoor.value
    },


    cssRight() {
      if (this.$store.state.configurate[this.produktType].build.rightExtra.value > 0) {
        return {
          '--bord': this.rama.image ? "url('" + this.APIhost + 'gfx/files/' + this.rama.image + "')" : '',
          '--wR1': (this.__rightExtraW / (this.__rightExtraH / (this.size.height * .4) )) + 'px',
          '--hR1': (this.__rightExtraH / (this.__rightExtraH / (this.size.height * .4) )) + 'px',
          '--bRT': this.rama.color,
        }
      }
      return 0
    },

  }

}
</script>
<style scoped>
  ._rEx {
    border-color: var(--rama)!important;
    border-image: var(--bord) 30 repeat !important;
    width: var(--wR1);
    height: var(--hR1);
    margin-right: 1px;
    border-color: var(--bRT);
  }
  ._frame {
    border-color: var(--bRT)!important;
  }
</style>