<template>
  
  <div class="_frame _lEx" :style="cssLeft" v-if="__leftExtraW">
    <div class="_leftglass">
      <div class="doorBorder">
        <div class="left"></div>
        <div class="right"></div>
      </div>
    </div>
    <img :src="glass" class="_glass" v-if="glass">
  </div>
  <div class="line-vert _lExr" v-if="__leftExtraW"></div> 
</template>

<script>
import Configure from '@/services/config.service';
export default {
  name: 'LeftExtra-Render',
  data() {
    return {
      APIhost: Configure.$HOST,
    }
  },

  props: {
    size: Object,
    rama: Object,
    glass: String
  },

  computed: {
    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },

    __leftExtraW() {
      if (this.$store.state.configurate[this.produktType].build.leftExtra.value > 0) {
        return this.$store.state.configurate[this.produktType].build.leftExtra.value
      }
      return 0
    },

    __leftExtraH() {
      return this.$store.state.configurate[this.produktType].build.heightDoor.value
    },


    cssLeft() {
      if (this.$store.state.configurate[this.produktType].build.leftExtra.value > 0) {
        return {
          '--bord': this.rama.image ? "url('" + this.APIhost + 'gfx/files/' + this.rama.image + "')" : '',
          '--wL1': (this.__leftExtraW / (this.__leftExtraH / (this.size.height * .4) )) + 'px',
          '--hL1': (this.__leftExtraH / (this.__leftExtraH / (this.size.height * .4) )) + 'px',
          '--bLT': this.rama.color
        }
      }
      return 0
    },


  }

}
</script>
<style scoped>
  ._lEx {
    border-color: var(--rama)!important;
    border-image: var(--bord) 30 repeat !important;
    width: var(--wL1);
    height: var(--hL1);
    margin-left: 1px;
    border-color: var(--bLT);
  }
  ._frame {
    border-color: var(--bLT)!important;
  }
</style>