export default class WindowsClass {
    
    Data = [];
    List = {};
 
    constructor(data) 
    {
      this.Data = data
    }

    setItems(data)
    {
      // var i = 0
      var items = []
      for (const element of Object.values(data)) {

        // items[i] = []

        var texture = null
        var textureId = null
        var protectOut  = null
        var protectOutId = null
        var protectIn  = null
        var protectInId = null
        var search = ''

        if (Array.isArray(element.codes)) {
          element.codes.forEach((element) => {
            search += element + ' '
          })
        }
        search += element.title

        if (element.image == 0) {
          element.image = parseInt(element.image)
        }

        if (element.cost == '') {
          element.cost = 0
        }


        for (const [key, value] of Object.entries(element.attribs)) {
          if (key == 11) {
            const vTexture = value
            texture = typeof Object.values(vTexture)[0] !== "undefined" ? Object.values(vTexture)[0] : ''
            textureId = typeof Object.keys(vTexture)[0] !== "undefined" ? Object.keys(vTexture)[0] : 0
          }
          if (key == 12) {
            const vProtectI = value
            protectIn = typeof Object.values(vProtectI)[0] !== "undefined" ? Object.values(vProtectI)[0] : ''
            protectInId = typeof Object.keys(vProtectI)[0] !== "undefined" ? Object.keys(vProtectI)[0] : 0
            
          }
          if (key == 13) {
            const vProtectO = value
            protectOut = typeof Object.values(vProtectO)[0] !== "undefined" ? Object.values(vProtectO)[0] : ''
            protectOutId = typeof Object.keys(vProtectO)[0] !== "undefined" ? Object.keys(vProtectO)[0] : 0
          }
        }

// console.log(element);
        items.push(Object.assign(
          { 
            id: parseInt(element.id), 
            counter: parseInt(element.counter),
            title: element.title, 
            fulltitle: element.fulltitle, 
            subtitle: element.subtitle, 
            code: element.code,
            text: element.sdesc,
            kind: element.kind,
            kindLabel: element.kind_label,
            image: element.image,
            cost: element.cost,
            ssearch: search,
            // attribs: Object.assign(element.attribs),
            texture: texture,
            textureId: parseInt(textureId),
            protectOut: protectOut,
            protectOutId: parseInt(protectOutId),
            protectIn: protectIn,
            protectInId : parseInt(protectInId),
            images:{
              57: element.attribs[57], 
              58: element.attribs[58], 
            }
          }
        ))
        
        // i = i + 1
      }

      // console.log(items);
      return items
    }

    sort(data) 
    {
      return data.sort((a, b) => {
        return a.counter - b.counter
      })
    }

    getWindows() 
    {
      var nList = this.setItems(this.Data)
      this.List = this.sort(nList)

      return this.List
    }
}