<template>
  
  <div class="top" v-if="__topExtraH">
    <div class="_frame _tEx" :style="cssTop">
      <div class="_topglass">
        <div class="doorBorder">
          <div class="leftTop"></div>
          <div class="rightTop"></div>
          <div class="leftBot"></div>
          <div class="rightBot"></div>
        </div>
      </div>
      <img :src="glass" class="_glass" v-if="glass">
    </div>
  </div>
</template>

<script>
import Configure from '@/services/config.service';
export default {
  name: 'TopExtra-Render',
  data() {
    return {
      APIhost: Configure.$HOST,
    }
  },

  props: {
    size: Object,
    rama: Object,
    glass: String,
    border: Number
  },

  computed: {
    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },

    __topExtraW() {
      var left1 = 0
      var right1 = 0
      var door1 = parseInt(this.$store.state.configurate[this.produktType].build.widthDoor.value)
      
      if (this.$store.state.configurate[this.produktType].build.leftExtra.value > 0) {
          left1 = parseInt(this.$store.state.configurate[this.produktType].build.leftExtra.value) + 3
      }
      if (this.$store.state.configurate[this.produktType].build.rightExtra.value > 0) {
          right1 = parseInt(this.$store.state.configurate[this.produktType].build.rightExtra.value) + 3
      }

      // console.log(door1 , left1 , right1, (door1 + this.border + left1 + right1 ));
      return door1 + left1 + right1 
    },

    __topExtraH() {
      if (this.$store.state.configurate[this.produktType].build.topExtra.value > 0) {
        return this.$store.state.configurate[this.produktType].build.topExtra.value
      }
      return 0
    },

    __heightdoor1() {
      return parseInt(this.$store.state.configurate[this.produktType].build.heightDoor.value)
    },


    cssTop() {
      if (this.$store.state.configurate[this.produktType].build.topExtra.value > 0) {
        console.log('top', this.__topExtraH, (this.__heightdoor1 / (this.size.height * .4)) );
        return {
          '--bord': this.rama.image ? "url('" + this.APIhost + 'gfx/files/' + this.rama.image + "')" : '',
          '--wT1': ((this.__topExtraW +this.border) / (this.__heightdoor1 / (this.size.height * .4) ))+ 'px',
          '--hT1': (this.__topExtraH / (this.__heightdoor1 / (this.size.height * .4) ))+ 'px',
          '--bT': this.rama.color
        }
      }
      return 0
    },

  }

}
</script>
<style scoped>
  ._tEx {
    border-color: var(--rama)!important;
    border-image: var(--bord) 30 repeat !important;
    width: var(--wT1);
    height: var(--hT1);
    border-color: var(--bT);
    border-bottom-style: solid !important;
  }
  ._frame {
    border-color: var(--rama)!important;
    border-image: var(--bord) 30 repeat !important;
    border-color: var(--bT)!important;
  }
</style>