<template>
  <div id="render">

    <div id="_door">
      <div class="door" :class="ifInsite">
        <TopExtra v-if="typeBD==47 || typeBD==48 || typeBD==49 || typeBD==50"
            :glass="doorWindow('topExtra')"
            :border="border"
            :size="{height:height, width: width}"
            :rama="fraCol" />

        <div class="central">
            <Door 
                :glass="doorWindow('door')"
                :border="border"
                :size="{height:height, width: width}"
                :rama="fraCol" />

            <LeftExtra v-if="typeBD==45 || typeBD==46 || typeBD==49 || typeBD==50"
                :glass="doorWindow('leftExtra')"
                :size="{height:height, width: width}"
                :rama="fraCol" />

            <RightExtra v-if="typeBD==44 || typeBD==46 || typeBD==48 || typeBD==50"
                :glass="doorWindow('rightExtra')"
                :size="{height:height, width: width}"
                :rama="fraCol" />
        </div>
      </div>

    </div>

  </div>
  
</template>
<script>
import Door from '@/components/render/DoorRender.vue';
import TopExtra from '@/components/render/TopExtraRender.vue';
import LeftExtra from '@/components/render/LeftExtraRender.vue';
import RightExtra from '@/components/render/RightExtraRender.vue';

// import domtoimage from 'dom-to-image-more';

import Configure from '@/services/config.service';
export default {
  name: 'Grafika-Render',
  data() {
    return {
      APIhost: Configure.$HOST,
      conf: Configure,
      skala: Configure.$KEY_Render.skala,
      height: 0,
      width: 0,
    }
  },

  components: {
    Door,
    TopExtra,
    LeftExtra,
    RightExtra,
  },

  methods: {

    // newImg() {
    //   var node = document.getElementById('_door');
    //   // var nnode = document.getElementById('canv');
    //   domtoimage
    //       .toJpeg(node, { quality: 0.5 })
    //       .then(function (dataUrl) {
    //           var img = new Image();
    //           img.src = dataUrl;
    //           console.log(img);
    //         // 
    //           // nnode.appendChild(img);
    //           // return img
    //           // document.body.appendChild(img);
   
    //           this.render = true
    //           return img
    //       })
    //       .catch(function (error) {
    //           console.error('oops, something went wrong!', error);
    //       });

    //       return false
    // },


    resizeHandler() {
      this.height = window.innerHeight;
      this.width = window.innerWidth;
    },

    
    doorWindow(key) {
      if (!this.$store.state.configurate[this.produktType].accesories.window[key]) {
        return null
      }
      
      if (typeof this.$store.state.configurate[this.produktType].accesories.window[key].model !== 'undefined') {
        if (this.__inout == 11) {
          return this.APIhost + 'gfx/files/' + this.$store.state.configurate[this.produktType].accesories.window[key].model.file
        } else {
          return this.APIhost + 'gfx/files/' + this.$store.state.configurate[this.produktType].accesories.window[key].model.file
        }
      } else {
        return null
      }
    },
  },

  mounted() {
    this.height = window.innerHeight;
    this.width = window.innerWidth;
  },

  created() {
    window.addEventListener("resize", this.resizeHandler);
  },

  unmounted() {
    window.removeEventListener("resize", this.resizeHandler);
  },

  computed: {
    
    border() {
      return this.conf.$KEY_Render.rama[this.produktType]
    },

    fraCol() { // full data
      return this.$store.state.configurate[this.produktType].color.frameColor
    },

    typeBD() {
      return this.$store.state.configurate[this.produktType].build.buildDoor.value;
    },

    ifInsite() { // 11-in // 12-out
      if (this.$store.state.configurate[this.produktType].build.viewDoor.value == 11) {
        return 'resite';
      }
      return null
    },

    __inout() { // 11-in // 12-out
      return this.$store.state.configurate[this.produktType].build.viewDoor.value
    },

    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },


    cssRender() {
      return {
        '--hRend': (this.__heightdoor1 / (this.__heightdoor1 / (this.size.height * .4) )) + 'px',
      }
    },

  }

}
</script>

<style>
  .renderSize {
    min-height: var(--hRend);
  }
</style>