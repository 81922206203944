export default class MenuClass {
    
    Data = {};
    Router = {};
    Menu = {};
 
    constructor(data, router) 
    {
      this.Data = data 
      this.Router = router
    }

    setPath(data)
    {
      var items = []

      if (Array.isArray(data)) {
        data.forEach((element) => {
          this.Router.options.routes.forEach(route => {
            if (element.id == route.id) {
              var i = {
                id: element.id,
                counter: element.counter,
                title: element.title,
                path: route.path
              }
              items.push(i)
            }
          })
        })
      }
      return items
    }

    sort(data) 
    {
      return data.sort((a, b) => {
        return a.counter - b.counter
      })
    }

    getMenu() 
    {
      var nMenu = this.setPath(this.Data)
      this.Menu = this.sort(nMenu)

      return this.Menu
    }
}