<template>
  <div id="naviDrobdown">
    <button type="button" data-bs-toggle="dropdown" aria-expanded="false">
      <img src="../assets/hamburger.svg" />
    </button>
    <ul class="dropdown-menu dropdown-menu-end">
      <li v-for="nav in navs" v-bind:key="nav">
        <div v-if="(nav.property.action == 'logout')">
          <div v-if="isLogged">
            <hr class="dropdown-divider" />
            <a class="dropdown-item" @click="logout()">
              {{ nav.name }}
            </a>
          </div>
        </div>
        <div v-else>
          <div v-if="nav.id != 10">
            <router-link v-if="isLogged" class="dropdown-item" :to="nav.path">
              {{ nav.name }}
            </router-link>
          </div>
          <router-link v-else class="dropdown-item" :to="nav.path">
            {{ nav.name }}
          </router-link>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'Navi-Menu',
  data() {
    return {
      navs: []
    }
  },

  methods: {
    logout() {
      this.$cookies.remove("session_account");
      this.$store.commit("updateAccount", Object.assign({ logged: false, id: 0, name: '', user: '', pswd: '', email: '', status: 0, date_login: '', date_session: '', date_register: '', last_login: '', remember: '', session: '' }));
    },

    sort(data) {
      return data.sort((a, b) => {
        return a.counter - b.counter
      })
    },
  
  },

  created() {
    const router = this.$router.options
    router.routes.forEach(element => {
      if (element.property.navi == true) {
        this.navs.push(Object.assign(element, { counter: element.property.counter }))
      }
    });

    this.navs = this.sort(this.navs)
  },

  computed: {
    
    isLogged() {
      this.$store.commit("isLogged")
      return this.$store.state.account.logged
    },
    
  }

}
</script>