<template>
  
  <div class="_frame _dSt" :style="cssDoor">
  
    <div v-if="__prod"
        :class="{ 'inLeft' : __wayDoor==7 || __wayDoor==51 }"
        id="_door1" >

      <div class="doorBorder">
        <div class="left"></div>
        <div class="right"></div>
      </div>

      <div v-if="__fillDoor == 42"
        :style="cssBorder"
        class="fb wsad _wsadb"></div>

      <div v-if="__fillDoor == 41 && __inout == 11"
        :style="cssBorder"
        class="fb onesize _wsadb"></div>

      <div id="_handles"
          v-if="showImage(__handles)">
          <img :src="this.APIhost + 'gfx/files/' + __handles" />
      </div>

      <div id="_rosette"
          v-if="showImage(__rosette)">
          <img :src="this.APIhost + 'gfx/files/' + __rosette" />
      </div>

      <div id="_kicker" 
          v-if="__kick">
          <img :src="this.APIhost + 'gfx/files/' + __kick" />
      </div>

      <div class="_accesor">
        <div id="_handle1"></div>
        
       
        <div id="drzwi">
          <img :src="imageView" />
        </div>
        
      </div>

    </div>
    <img :src="glass" class="_glass" v-if="glass">
  </div>
</template>

<script>
import Configure from '@/services/config.service';
// import AppService from '@/services/app.service';

export default {
  name: 'Door1-Render',
  data() {
    return {
      APIhost: Configure.$HOST,
      drama: {
        inside: String,
        outside: String
      }
    }
  },

  props: {
    size: Object,
    rama: Object,
    glass: String,
    border: Number
  },

  methods: {
    dramaColor() {
      this.inside = this.__inColor1.color
      this.outside = this.__outColor1.color
    },


    showImage(src) {
      return src != null ? true : false
    }

  },

  created() {
    this.dramaColor()
  },

  computed: {
    imageView() {
      let color = ''
      let model = ''

      if (this.__inout == 11) {
        if (this.__inColor1.value != 0) {
          color = this.__inColor1.value
          model = this.__model == 2 ? '_flat_' : '_'
        }
      }

      if (this.__inout == 12) {
        if (this.__outColor1.value != 0) {
          color = this.__outColor1.value
          model = '_'
        }
      }

      return this.APIhost + 'gfx/files/' + color + model + this.__prod
    },

    __wayDoor() {
      return this.$store.state.configurate[this.produktType].build.wayDoor.value
    },

    __outColor1() {
      return this.$store.state.configurate[this.produktType].color.outdoorColor
    },

    __inColor1() {
      return this.$store.state.configurate[this.produktType].color.indoorColor
    },

    __inout() {
      // 11-in // 12-out
      return this.$store.state.configurate[this.produktType].build.viewDoor.value
    },

    side() {
      return this.__inout == 11 ? 'inside': 'outside'
    },

    __model() {
      return this.$store.state.configurate[this.produktType].design.in.wzor.value
    },

    __prod() {
      if (!this.$store.state.configurate[this.produktType].design.product.image) {
        return false
      }
      return this.$store.state.configurate[this.produktType].design.product.image[this.side].name
    },

    // __prod2() {
    //   return this.$store.state.configurate[this.produktType].design.product2.value
    // },

    // __door1() {
    //   if (this.$store.state.rendering.door1) {
    //     return this.$store.state.rendering.door1
    //   }
    //   return null
    // },

    __widthdoor1() {
      return parseInt(this.$store.state.configurate[this.produktType].build.widthDoor.value)
    },

    __heightdoor1() {
      return parseInt(this.$store.state.configurate[this.produktType].build.heightDoor.value)
    },

    __fillDoor() {
      return this.$store.state.configurate[this.produktType].build.fillDoor.value
    },

    __rosette() {
      var color = this.$store.state.configurate[this.produktType].accesories.rosette[this.side].color.value
      var image = this.$store.state.configurate[this.produktType].accesories.rosette[this.side].model.image
      
      return (color > 0) ? color + '_' + image : null
    },

    __kick() {
      var color = this.$store.state.configurate[this.produktType].accesories.kick[this.side].color.value
      var image = this.$store.state.configurate[this.produktType].accesories.kick[this.side].model.image
      
      return (color > 0) ? color + '_' + image : null
      
    },

    __handles() {
        var image = this.$store.state.configurate[this.produktType].accesories.hand[this.side].model.image
        var size = this.$store.state.configurate[this.produktType].accesories.hand[this.side].height.value
        var color = this.$store.state.configurate[this.produktType].accesories.hand[this.side].color.value
        
        size = (size !='' && size!=null) ? size + '_' : ''
        color = (color !='' && color!=null && color>0) ? color + '_' : ''
        if (typeof image !== 'undefined') {
          image = (typeof image !== 'undefined') ? color + size + image : null
        } else {
          image = null
        }

      return image
    },


    cssDoor() {
      return {
        '--bord': this.rama.image ? "url('" + this.APIhost + 'gfx/files/' + this.rama.image + "')" : '',
        '--wD1': ((this.__widthdoor1 + this.border) / (this.__heightdoor1 / (this.size.height * .4) )) + 'px',
        '--hD1': (this.__heightdoor1 / (this.__heightdoor1 / (this.size.height * .4) )) + 'px',
        '--rama': this.rama.color,
      }
    },

    
    cssBorder() {
      if (this.__inout == 11) {
        return {
          '--drama': this.__inColor1.color,
        }
      } else {
        return {
          '--drama': this.__outColor1.color,
        }
      }
      
    },

    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },


  }

}
</script>
<style>
  ._dSt {
    border-color: var(--rama)!important;
    border-image: var(--bord) 30 round !important;
    width: var(--wD1);
    height: var(--hD1);
  }
  ._frame {
    border-color: var(--rama)!important;
  }
  .fb {
    border-color: var(--drama)!important;
  }
  ._wsadb {
    border-color: var(--drama)!important;
    border-image: url(@/assets/render/wsad.png) 30 round !important;
  }
</style>