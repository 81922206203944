<template>
  <div id="shortConfig">
    <div class="block" v-for="items in menu" v-bind:key="items">
      <div class="header"> {{ items.label }}</div>
      <Range :data="items.value" :id="items.id" />
    </div>
  </div>
</template>

<script>
import RangeClass from '@/classes/RangeClass';
import AppService from '@/services/app.service';
import Range from '@/components/mods/Range.vue'

export default {
  name: 'Opcje-Range',
  data() {
    return {
      menu: []
    }
  },

  components: {
    Range,
  },

  created() {

    /* Menu */
    AppService.getSection(1336).then(
      (response) => {
        var MyRange = new RangeClass(response.data.data);
        this.menu = MyRange.getOption()
      }
    ).catch(error => {
      this.$store.commit("errors", 'Short.vue, getSection(1336), ' + error);
    })

  },

}
</script>
