import axios from 'axios'
import config from '@/services/config.service';


class AppService {

  getSteps() {
    return axios.get(config.$API + 'getSteps', 
      { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getStep(id) {
    return axios.get(config.$API + 'getSteps&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getSection(id) {
    return axios.get(config.$API + 'getSection&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getDoors() {
    return axios.get(config.$API + 'getDoors', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getGroupDoors(id) {
    return axios.get(config.$API + 'getDoors&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getIDDoor(id) {
    return axios.get(config.$API + 'getDoor&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getColors() {
    return axios.get(config.$API + 'getColors', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getIDColor(id) {
    return axios.get(config.$API + 'getColor&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getAntabes() {
    return axios.get(config.$API + 'getAntables', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getAntabe(id) {
    return axios.get(config.$API + 'getAntable&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getHandles() {
    return axios.get(config.$API + 'getHandles', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getHandle(id) {
    return axios.get(config.$API + 'getHandle&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getWindows() {
    return axios.get(config.$API + 'getWindows', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getIDWindows(id) {
    return axios.get(config.$API + 'getWindows&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getWindow(id) {
    return axios.get(config.$API + 'getWindow&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getRosettes() {
    return axios.get(config.$API + 'getRosettes', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getRosette(id) {
    return axios.get(config.$API + 'getRosette&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getKicks() {
    return axios.get(config.$API + 'getKicks', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getKick(id) {
    return axios.get(config.$API + 'getKick&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getElectros() {
    return axios.get(config.$API + 'getElectros', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }
  getElectro(id) {
    return axios.get(config.$API + 'getElectro&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getProperty(id) {
    return axios.get(config.$API + 'getProperty&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getDocument(id) {
    return axios.get(config.$API + 'getDocument&pKey=' + id + '&source=1&format=html', 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }







  login({login,password}) {
    return axios.get(config.$API + 'setUser&login=' + login +'&haslo=' + password, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  getToken(id) {
    return axios.get(config.$API + 'getAuth&pKey=' + id, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }


  saveOrder(data) {
    console.log(data);
    return axios.post(config.$API + 'saveOrder', data, 
    { 'Cache-Control': 'no-cache',  'Pragma': 'no-cache', 'Expires': '0' });
  }

//   deleteAdminNew(id) {
//     var nowDate = new Date()
//     return axios.delete(config.$API + 'admin/news/' + id, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
//   }
//   postAdminNew(data) {
//     var nowDate = new Date()
//     return axios.post(config.$API + 'admin/news', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
//   }
//   putAdminNew(data) {
//     var nowDate = new Date()
//     return axios.put(config.$API + 'admin/news', data, { headers: authHeader(), 'Cache-Control': 'no-cache', 'Pragma': 'no-cache', 'Expires': '0', cache: false, ts: nowDate.getTime() });
//   }


}

export default new AppService();