import { createStore } from 'vuex'
import cookies from 'vue-cookies'
export default createStore({
  state: 
  {
    configurate: 
    {

      panel1: 
      {
        design: 
        {
          category: 
          {
            id: 2,
            name: null,
            value: 9
          },
          product: 
          {
            id: 131, 
            name: "LCF 217.1", 
            value: 273,
            image: {},
          },
          model: 
          {
            id: 52, 
            name: "Panel ozdobny", 
            value: 1
          },
        
        },


        build: {
          buildPanel: {
            id: 0,
            name: null,
            value: 77
          },
          // widthPanelOut: {
          //   id: 49,
          //   name: null,
          //   value: 0
          // }, 
          // heightPanelOut: {
          //   id: 48,
          //   name: null,
          //   value: 0
          // }, 
          // bignessPanelOut: {
          //   id: 50,
          //   name: null,
          //   value: 0
          // }, 
          // widthPanel2Out: {
          //   id: 49,
          //   name: null,
          //   value: 0
          // }, 
          // heightPanel2Out: {
          //   id: 48,
          //   name: null,
          //   value: 0
          // }, 
          // bignessPanel2Out: {
          //   id: 50,
          //   name: null,
          //   value: 0
          // }, 
          // widthPanelIn: {
          //   id: 49,
          //   name: null,
          //   value: 0
          // }, 
          // heightPanelIn: {
          //   id: 48,
          //   name: null,
          //   value: 0
          // }, 
          // bignessPanelIn: {
          //   id: 50,
          //   name: null,
          //   value: 0
          // }, 
          // widthPanel2In: {
          //   id: 49,
          //   name: null,
          //   value: 0
          // }, 
          // heightPanel2In: {
          //   id: 48,
          //   name: null,
          //   value: 0
          // }, 
          // bignessPanel2In: {
          //   id: 50,
          //   name: null,
          //   value: 0
          // }, 
          // widthPanelW: {
          //   id: 49,
          //   name: null,
          //   value: 0
          // }, 
          // heightPanelW: {
          //   id: 48,
          //   name: null,
          //   value: 0
          // }, 
          // bignessPanelW: {
          //   id: 51,
          //   name: null,
          //   value: 0
          // }, 
          // widthPanel2W: {
          //   id: 49,
          //   name: null,
          //   value: 0
          // }, 
          // heightPanel2W: {
          //   id: 48,
          //   name: null,
          //   value: 0
          // }, 
          // bignessPanel2W: {
          //   id: 51,
          //   name: null,
          //   value: 0
          // }, 
        }
      },


      // panel2: {
      //   design: 
      //   {
      //     category: 
      //     {
      //       id: 2,
      //       name: null,
      //       value: 9
      //     },
      //     product: 
      //     {
      //       id: 131, 
      //       name: "LCF 217.1", 
      //       value: 273,
      //       image: {},
      //     },
      //     model: 
      //     {
      //       id: 52, 
      //       name: "Panel ozdobny", 
      //       value: 1
      //     },
        
      //   },
      // },



      door: 
      {
        design: 
        {
          category: 
          {
            id: 0,
            name: null,
            value: 0
          },
          product: 
          {
            id: 0, 
            name: null, 
            value: 0,
            producent: null,
            glass: {},
            image: {},
          },
          in: {
            wzor: 
            {
              id: 0, 
              name: null, 
              value: 0
            },
            file: null,
          }, 
          out: {
            wzor: 
            {
              id: 0, 
              name: null, 
              value: 0
            },
            file: null,
          }, 
        },


        build: 
        {
          buildDoor: 
          {
            id: 29,
            name: "Drzwi",
            value: 44
          }, 
          widthDoor: 
          {
            id: 0,
            name: null,
            value: 0,
          }, 
          heightDoor: 
          {
            id: 0,
            name: null,
            value: 0,
          }, 
          fillDoor: 
          {
            id: 4,
            name: "2-stronnie nakładkowy",
            value: 40
          }, 
          wayDoor: 
          {
            id: 0,
            name: null,
            value: 51
          }, 
          viewDoor: 
          {
            id: 5,
            name: "od zewnątrz",
            value: 12, // 11
          },
          leftExtra: 
          {
            id: 0,
            name: null,
            value: 0,
          }, 
          rightExtra: 
          {
            id: 0,
            name: null,
            value: 0,
          }, 
          topExtra: 
          {
            id: 0,
            name: null,
            value: 0
          }, 
        },


        color: 
        {
          outdoorColor: 
          {
            id: 8,
            name: null,
            value: 0, 
            color: null, 
            text: null,
            file: null
          }, 
          indoorColor: 
          {
            id: 8, 
            name: null, 
            value: 0, 
            color: null, 
            text: null,
            file: null
          }, 
          frameColor: 
          {
            id: 0, 
            name: null, 
            value: 0, 
            color: null, 
            text: null,
            file: null
          },
        },


        accesories: 
        {
          hand: 
          {
            inside: 
            {
              model: 
              {
                id: 0, 
                name: null, 
                value: 0, 
                text: null,
                producent: null,
                file: null
              },
              dimension: 
              {
                id: 0, 
                name: null, 
                phi: 0,
                width: 0,
                height: 0
              },
              height: {
                id: 0, 
                name: null, 
                value: 0
              },
              type: 
              {
                id: 0, 
                name: null, 
                value: 0
              },
              color: 
              {
                id: 0,
                name: null,
                value: 0,
                color: null, 
                text: null,
                image: null
              },
              way: {
                id: 0, 
                name: null, 
                value: 0
              },
              side: {
                id: 0, 
                name: null, 
                value: {}
              }
            },

            outside: 
            {
              model: 
              {
                id: 0, 
                name: null, 
                value: 0, 
                producent: null,
                file: null
              },
              section: 
              {
                id: 0, 
                name: null, 
                value: 0,
              },
              crossHeight: 
              {
                id: 0, 
                name: null, 
                value: 0,
              },
               crossWidth: 
              {
                id: 0, 
                name: null, 
                value: 0,
              },
              height: {
                id: 0, 
                name: null, 
                value: 0
              },
              type: 
              {
                id: 0, 
                name: null, 
                value: 0
              },
              color: 
              {
                id: 0,
                name: null,
                value: 0,
                color: null, 
                text: null,
                image: null
              },
              way: {
                id: 0, 
                name: null, 
                value: 0
              },
              side: {
                id: 0, 
                name: null, 
                value: {}
              }
            }
          },



          window: 
          {
            door: 
            {
              // model: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   producent: null, 
              //   file: null
              // },
              // kind: 
              // {
              //   id: 67,
              //   name: null, 
              //   value: 0
              // },
              // type: 
              // {
              //   id: 10,
              //   name: null,
              //   value: 0
              // },
              // in: 
              // {
              //   protectWindow: 
              //   {
              //     id: 12,
              //     name: null,
              //     value: 0
              //   },
              // },
              // out: 
              // {
              //   protectWindow: 
              //   {
              //     id: 13,
              //     name: null,
              //     value: 0
              //   },
              // },
              
              // textureWindow: 
              // {
              //   id: 11,
              //   name: null,
              //   value: 0
              // },
              
              // color: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   color: null,
              //   text: null,
              //   file: null
              // }, 
            },

            leftExtra: 
            {
              // model: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   producent: null,
              //   file: null
              // },
              // kind: 
              // {
              //   id: 67,
              //   name: null, 
              //   value: 0
              // },
              // type: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0
              // },
              // textureWindow: 
              // {
              //   id: 11,
              //   name: null,
              //   value: 0
              // },
              // in: 
              // {
              //   protectWindow: 
              //   {
              //     id: 12,
              //     name: null,
              //     value: 0
              //   },
              // },
              // out: 
              // {
              //   protectWindow: 
              //   {
              //     id: 13,
              //     name: null,
              //     value: 0
              //   },
              // },
              // color: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   color: null,
              //   text: null,
              //   file: null
              // }, 
            },

            rightExtra: 
            {
              // model: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   producent: null,
              //   file: null
              // },
              // kind: 
              // {
              //   id: 67,
              //   name: null, 
              //   value: 0
              // },
              // type: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0
              // },
              // textureWindow: 
              // {
              //   id: 11,
              //   name: null,
              //   value: 0
              // },
              // in: 
              // {
              //   protectWindow: 
              //   {
              //     id: 12,
              //     name: null,
              //     value: 0
              //   },
              // },
              // out: 
              // {
              //   protectWindow: 
              //   {
              //     id: 13,
              //     name: null,
              //     value: 0
              //   },
              // },
              // color: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   color: null,
              //   text: null,
              //   file: null
              // }, 
            },


            topExtra: 
            {
              // model: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   producent: null,
              //   file: null
              // },
              // kind: 
              // {
              //   id: 67,
              //   name: null, 
              //   value: 0
              // },
              // type: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0
              // },
              // textureWindow: 
              // {
              //   id: 11,
              //   name: null,
              //   value: 0
              // },
              // in: 
              // {
              //   protectWindow: 
              //   {
              //     id: 12,
              //     name: null,
              //     value: 0
              //   },
              // },
              // out: 
              // {
              //   protectWindow: 
              //   {
              //     id: 13,
              //     name: null,
              //     value: 0
              //   },
              // },
              // color: 
              // {
              //   id: 0,
              //   name: null,
              //   value: 0,
              //   color: null,
              //   text: null,
              //   file: null
              // }, 
            },

          },



          kick: 
          {
            inside: 
            {
              model:
              {
                id: 0, 
                name: null,
                value: 0,
                producent: null,
                file: null
              },
              size: 
              {
                id: 23,
                name: null,
                value: 0
              },
              color: 
              {
                id: 0, 
                name: null,
                value: 0,
                color: null,
                text: null,
                file: null
              }
            },

            outside: 
            {
              model: 
              {
                id: 0, 
                name: null,
                value: 0,
                producent: null,
                file: null
              },
              size: 
              {
                id: 23,
                name: null,
                value: 0
              },
              color: 
              {
                id: 0, 
                name: null,
                value: 0,
                color: null,
                text: null,
                file: null
              },
            },
          },


          rosette: 
          {
            inside: 
            {
              model: 
              {
                id: 0,
                name: null,
                value: 0,
                producent: null,
                file: null
              },
              type: 
              {
                id: 21,
                name:null,
                value: 0
              },
              color: 
              {
                id: 0, 
                name: null,
                value: 0,
                color: null,
                text: null,
                file: null
              },
            },

            outside: 
            {
              model: 
              {
                id: 0,
                name: null,
                value: 0,
                producent: null,
                file: null
              },
              type: 
              {
                id: 21,
                name: null,
                value: 0
              },
              color: 
              {
                id: 0, 
                name: null,
                value: 0,
                color: null,
                text: null,
                file: null
              },
            },
          },



          controls: 
          {
            inside: 
            {
              // control1: 
              // {
              //   model: 
              //   {
              //     id: 0,
              //     name: null,
              //     value: 0,
              //     producent: null
              //   },
              //   type: 
              //   {
              //     id: 0,
              //     name: null,
              //     value: 0
              //   },
              // },
            },

            outside:
            {
              // control1: 
              // {
              //   model: 
              //   {
              //     id: 0,
              //     name: null,
              //     value: 0,
              //     producent: null
              //   },
              //   type: 
              //   {
              //     id: 0,
              //     name: null,
              //     value: 0
              //   },
              // },
            }
          },

        },


        bariers: 
        {
          status: 
          {
            id: 0,
            name: null,
            value: 0,
          },
        },

      },


    },

    account: 
    {
      logged: false,
      id: 0, 
      name: '', 
      user: null, //'hokus',
      pswd: null, //'hokuspokus',
      email: '',
      status: 0,
      date_login: '',
      date_session:'',
      date_register: '',
      last_login: '',
      remember: '',
      session: '',
    },


    helpers: 
    {
      names: null, // for anonim
      email: null, // for anonim
      phone: null, // for anonim
      info: null, // for anonim

      token: null,
      typeBuild: 77, // drzwi czy panel - wybrane drzwi // tak zostawić
      view: 0,
      antabe: 0,
      panel: 0,
      door: 1,
      handle: 0,
      showView: 11, // tak zostawić
      showWay: 7, // tak zostawić
      // colorCode: [], // do szukania

      offCanvas_door1: false,
      offCanvas_door2: false,
      offCanvas_colorFrame: false,
      offCanvas_colorDoor: false,
      // offCanvas (on/off)

      glassDoorTexture: 0,
      glassDoorProtectIn: 0,
      glassDoorProtectOut: 0,

      glassExtraTexture: 0,
      glassExtraProtectIn: 0,
      glassExtraProtectOut: 0,

      glassPanelTexture: 0,
      glassPanelProtectIn: 0,
      glassPanelProtectOut: 0,

      kickIn: 0,
      kickOut: 0,

      rosetteIn: 0,
      rosetteOut: 0,

      controlIn: 0,
      controlOut: 0,

      defaultGlass: {},
      defaultExtraGlass: {},

      

      search_door_out: 
      {
        text: '',
        type: '',
        tab: null
      }, 
      search_door_in: 
      {
        text: '',
        type: '',
        tab: null
      },
      search_frame: 
      {
        text: '',
        type: '',
        tab: null
      },
      search_pan1_out: 
      {
        text: '',
        type: '',
        tab: null
      }, 
      search_pan1_in: 
      {
        text: '',
        type: '',
        tab: null
      },
      search_pan2_out: 
      {
        text: '',
        type: '',
        tab: null
      }, 
      search_pan2_in: 
      {
        text: '',
        type: '',
        tab: null
      },

      
    },

    toast: 
    {
      title: '',
      text: '',
      time: '',
      type: ''
    },

    rendering: 
    {
      door1: [],
      door2: [],
      topPanel:[],
      leftPanel:[],
      rightPanel:[],
      frame: [],
    },

    errors: [],

  },


  getters: {
    
  },


  mutations: {

    updateBuildConfig(state, data) { // data {data, state}
      state.configurate = data.data
      for (const [k0, v0] of Object.entries(state)) {
        // var _s1 = {}

        if (k0 == 'configurate') {
          for (const [k1, v1] of Object.entries(state.configurate[data.state])) {
            var _s2 = {}

            for (const [k2, v2] of Object.entries(v1)) {
              Object.assign(_s2, { [k2]: Object.assign(v2) })
            }
            localStorage.setItem(k1, JSON.stringify(_s2))
          }
          
        } else {
          // Object.assign(_s1, { [k0]: Object.assign(v0) })
          localStorage.setItem(k0, JSON.stringify(Object.assign(v0)))
          // localStorage.setItem(k0, JSON.stringify(_s1))
        }
      }
    },

    // updateBuildConfig(state, data) { // data {data, state}
    //   state.configurate = data.data

    //   for (const [k1, v1] of Object.entries(state.configurate[data.state])) {
    //     var _s2 = {}

    //     for (const [k2, v2] of Object.entries(v1)) {
    //       Object.assign(_s2, { [k2]: Object.assign(v2) })
    //     }

    //     localStorage.setItem(k1, JSON.stringify(_s2))
    //   }

    // },

    renewConfig(state, data) { // data { type }
      for (const k0 of Object.keys(state)) {

        if (k0 == 'configurate') {
          for (const [k1, v1] of Object.entries(state.configurate[data.type])) {
            var temp = null
            for (const k2 of Object.keys(v1)) {

              if (JSON.parse(localStorage.getItem(k1))) {

                temp = JSON.parse(localStorage.getItem(k1))
                state.configurate[data.type][k1] = temp

                if (temp[k2]) {
                  state.configurate[data.type][k1][k2] = temp[k2]
                } 
              }
            }
          }

        } else {
          // console.log(k0);
          if (JSON.parse(localStorage.getItem(k0))!= null) {
            console.log(k0, JSON.parse(localStorage.getItem(k0)));
            state[k0] = JSON.parse(localStorage.getItem(k0))
          }
          
        }
        
      }
    },

    // renewConfig(state, data) { // data { type }
    //   for (const [k1, v1] of Object.entries(state.configurate[data.type])) {
    //     var temp = null
    //     for (const k2 of Object.keys(v1)) {

    //       if (JSON.parse(localStorage.getItem(k1))) {

    //         temp = JSON.parse(localStorage.getItem(k1))
    //         state.configurate[data.type][k1] = temp

    //         if (temp[k2]) {
    //           state.configurate[data.type][k1][k2] = temp[k2]
    //         } 
    //       }
    //     }
    //   }
    // },

    updateConfig(state, data) { // data { type, group, element, value }
      var _new = {}
      var _temp = {}

      if (localStorage.getItem(data.group)) {
        var temp = JSON.parse(localStorage.getItem(data.group))
        for (const [key, value] of Object.entries(temp)) {
          Object.assign(_new, { [key]: value })
        }
      }

      for (const [key2, value1] of Object.entries(data.value)) {
        Object.assign(_temp, { [key2]: value1 })
      }
      
      Object.assign(_new, { [data.element]: Object.assign(_temp) })
      localStorage.setItem(data.group, JSON.stringify(_new))
      state.configurate[data.type][data.group][data.element] = _temp
    },



    updateControls(state, data) { // data { group, value }
      localStorage.setItem(data.group, JSON.stringify(data.value));
      state.configurate.doc[data.group] = data.value
    },


    deleteOption(state, data) { // option bariers
      console.log(state.configurate[data.type][data.group], data);
      state.configurate[data.type][data.group] = []
      localStorage.setItem(data.group, JSON.stringify(Object.assign(data.value)));
      state.configurate[data.type][data.group] = data.value
    },


    updateHelper(state, data) { // data { element, value }
      var _new = {}
      if (localStorage.getItem('helpers')) {
        var temp = JSON.parse(localStorage.getItem('helpers'))
        for (const [key, value] of Object.entries(temp)) {
          Object.assign(_new, { [key]: value })
        }
      }
      Object.assign(_new, { [data.element]: data.value } )
      localStorage.setItem('helpers', JSON.stringify(_new))
      state.helpers[data.element] = data.value
    },



    updateRendering(state, data) { // data { element, value }
      var _new = {}
      if (localStorage.getItem('rendering')) {
        var temp = JSON.parse(localStorage.getItem('rendering'))
        for (const [key, value] of Object.entries(temp)) {
          Object.assign(_new, { [key]: value })
        }
      }
      Object.assign(_new, { [data.element]: data.value } )
      localStorage.setItem('rendering', JSON.stringify(_new))
      state.rendering[data.element] = data.value
    },



    updateAccount(state, data) { // data { value }
      localStorage.setItem('account', JSON.stringify(data))
      state.account = data

    },


    updateToast(state, data) { // data { value }
      state.toast = data
    },


    errors (state, newVal) {
      console.log(newVal);
      state.errors.push(newVal);
      
    },



    isLogged(state) { // sprawdzanie czy zalogowany
      var log = false;
      var current = Math.round(+new Date() / 1000)
      // console.log(current);
      if (cookies.isKey("session_account")) {
        // console.log(current, 'sss',sesja);
        var sesja = cookies.get("session_account").date_session
        log = (current < sesja) ? true : false
      }

      // console.log(current, sesja);
      if (!log) {
        cookies.remove("session_account")
      }
      state.account.logged = log
      // console.log('log', log)
      return log
    },


  },

  actions: {},

  modules: {}
});
