export default class DoorClass {
    
    Data = [];
    List = {};
 
    constructor(data) 
    {
      this.Data = data
    }

    newObj(data, keys) {
      var item = {}

      for (const [k1, v1] of Object.entries(data)) {
        var name = keys[k1]
        Object.assign(item, { 
          [name]: Object.assign({ id: k1, name: v1 }) 
        })
      }
      return item
    }


    setItems(data)
    {
      var breakList = ['alias', 'odkiedy', 'handdate', 'desc', 'sdesc' ]
      var numberList = ['id', 'kind', 'counter']
      var items = {}


      for (const [key, element] of Object.entries(data)) {
        var item = element

        if (breakList.indexOf(key) == -1) {

          if (key == 'colorSides') {
            item = this.newObj(item, Object.assign({ 13:'outside', 14:'inside' }))
          }

          if (key == 'graphics') {
            item = this.newObj(item, Object.assign({ 46:'outside', 47:'inside' }))
          }

          if (key == 'attribs') {
            var panel = {}
            var status = false
            if (typeof item[55] !== 'undefined' ) { // panel wewn ozdobny
              status = (item[55] != null) ? true : false
              Object.assign(panel, Object.assign({ ['special']: {id: 55, status: status, file: item[55], name: 'Panel ozdobny' } }) )
              delete item[55];
            }

            if (typeof item[56] !== 'undefined' ) { // panel wewn standard
              status = (item[56] != null) ? true : false
              Object.assign(panel, Object.assign({ ['default']: {id: 56, status: status, file: item[56], name: 'Panel standard' } }) )
              delete item[56];
            }
            Object.assign(items, Object.assign({ ['panelInside']: panel }) )


            if (typeof item[67] !== 'undefined' ) { // szklo typ
              Object.assign(items, Object.assign({ ['glass']: this.newObj(item[67], Object.assign({105:'standard', 106:'protect'})) }) )
              delete item[67]
            }

            // delete item;
        
          }

          if (key == 'colors') {

            if (Object.keys(item['door']).length > 0) {
              var fDoorColor = {}
              const k1 = Object.entries(item['door'])
              const k2 = Object.entries(k1[0][1])
              fDoorColor = {
                id: 8,
                image: k2[0][1].image,
                name: k2[0][1].title,
                text: k2[0][1].name[0],
                color: k2[0][1].color,
                value: parseInt(k2[0][0])
              }
              Object.assign(items, Object.assign({ ['firstColorDoor']: fDoorColor }) )
            }

            if (Object.keys(item['frame']).length > 0) {
              var fFrameColor = {}
              const k1 = Object.entries(item['frame'])
              const k2 = Object.entries(k1[0][1])
              fFrameColor = {
                id: 9,
                image: k2[0][1].image,
                name: k2[0][1].title,
                text: k2[0][1].name[0],
                color: k2[0][1].color,
                value: parseInt(k2[0][0])
              }
              Object.assign(items, Object.assign({ ['firstColorFrame']: fFrameColor }) )
            }

          }

          Object.assign(items, { 
            [key]: (numberList.indexOf(key) >-1) ? parseInt(item) : item 
          })
        }

      }
      return items
    }

    sort(data) 
    {
      return data.sort((a, b) => {
        return a.counter - b.counter
      })
    }

    getDoor() 
    {
      return this.setItems(this.Data)
    }
}