<template>
  <header class="header" v-if="title">
    <h1>{{ title }}</h1>
    <p class="lead" v-if="subtitle">{{ subtitle }}</p>
  </header>
  
  <div v-html="page.desc"></div>
</template>

<script>
import AppService from '@/services/app.service';

export default {
  name: 'strona-glowna',

  data() {
    return {
      title: '',
      subtitle: null,
      page: {}
    }
  },



  created() {
    
    //  opis
    AppService.getDocument(51).then(
      (response) => {

        this.page = response.data;
        this.title = this.page.fulltitle != '' ? this.page.fulltitle : this.page.title
        this.subtitle = this.page.subtitle != '' ? this.page.subtitle : null
      }
    ).catch(error => {
      this.$store.commit("errors", 'Home.vue, getDocument(51), ' + error);
    })
  },

}
</script>
