<template>
  <Index v-if="checkLoad()"/>
  <Loader :data="loader" v-else />
</template> 

<script>
/* classess */
import HandlesClass from '@/classes/HandlesClass';
import AntabesClass from '@/classes/AntabesClass';

import WindowsClass from '@/classes/WindowsClass';
import DoorClass from '@/classes/DoorClass';

import AppService from '@/services/app.service';
import Config from '@/services/config.service';

import Index from '@/components/Index.vue'
import Loader from '@/components/mods/Loader.vue'

export default {
  data() {
    return {
      conf: Config,
      loader: null,
      ID: 157, // domyslny produkt

      product1: {},
      sizes: {
        width: Number,
        height: Number,
        extraLeftWidth: Number,
        extraRightWidth: Number,
        extraTopHeight: Number,
      },
      glassess: {
        window: {},
        extra: {},
      },
      kind: {},

      state: { // tmp dla danych state
        window: {
          door: {},
          leftExtra: {},
          rightExtra: {},
          topExtra: {},
        },
      }, 

      loading: {
        Produkty: false,
        // Panele: false,
        Kategorie: false,
        Kolory: false,
        // 'Klamki': false,
        'Oszklenie Drzwi': false,
        'Oszklenie Dostawek': false,
      },

      Handles: {},
      Antabes: {}

    }
  },

  components: {
    Index,
    Loader
  },



  methods: {
    /* Etapy ładowania modulow */
    checkLoad() {
      for (const [key, value] of Object.entries(this.loading)) {
        if (value == false) {
          this.loader = key;
          return false
        }
      }
      return true
    },

    setLoad(key) {
      this.loading[key] = true
    },



    /* start Nowe */

    setColor(key, data) {
      this.$store.commit("updateConfig", Object.assign({ 
            type: this.produktType, 
            group: 'color', 
            element: key, 
            value: (this.$Color[key].value > 0) ? this.$Color[key] : data
          })
      );
    },

    setModel(key, data) {
      this.$store.commit("updateConfig", Object.assign({ 
            type: this.produktType, 
            group: 'design', 
            element: key, 
            value: Object.assign({ 
              wzor: (this.$Design[key].value > 0) ? this.$Design[key] : data
            })
          })
      );
    },

    setGlass(data) {
      this.$store.commit("updateConfig", Object.assign({ 
            type: this.produktType, 
            group: 'accesories', 
            element: 'window', 
            value: Object.assign({ 
              door: data.door,
              leftExtra: data.leftExtra,
              rightExtra: data.rightExtra,
              topExtra: data.topExtra
            })
          })
      );
    },

    setSizes(element, data) {
      this.$store.commit("updateConfig", Object.assign({ 
            type: this.produktType, 
            group: 'build', 
            element: element, 
            value: data
          })
      );
    },

    /* zapis domyslnych szyb */
    setDefaultGlass(data, typ) {
      this.$store.commit(
        "updateHelper", 
        Object.assign({ 
          element: [typ], 
          value: data 
        })
      );
    },




    glassWindow() {
        AppService.getIDWindows(18).then( // oszklenie
        (response) => {
          var door = this.$Accesories.window.door
          
          var ListGlass = new WindowsClass(response.data).getWindows()
          this.glassess.window = ListGlass

          if (Object.keys(this.$Accesories.window.door) == '') {
            
            var firstEl = this.glassess.window[0]
            var kindof = (parseInt(firstEl.protectInId) == 23 && parseInt(firstEl.protectOutId) == 110) ? this.kind.values[0] : this.kind.values[1]

              door = {  model: { id: this.conf.$KEY_WindowModel, name: firstEl.title, value: parseInt(firstEl.id), producent: firstEl.code, file: (firstEl.images[58] != null) ? firstEl.images[58] : firstEl.images[57], },
                        kind: { id: this.conf.$KEY_KindWindow, name: kindof.vname, value: parseInt(kindof.iid), },
                        type: { id: this.conf.$KEY_TypeWindow, name: firstEl.kindLabel, value: parseInt(firstEl.kind), },
                        textureWindow: { id: this.conf.$KEY_TextureWindow, name: firstEl.texture, value: parseInt(firstEl.textureId), },
                        in: { protectWindow: { id: this.conf.$KEY_ProtectWindowIn, name: firstEl.protectIn, value: parseInt(firstEl.protectInId),}, },
                        out: { protectWindow: { id: this.conf.$KEY_ProtectWindowOut, name: firstEl.protectOut, value: parseInt(firstEl.protectOutId), }, },
                        color: { id: 0, name: null, value: 0, color: null, text: null, file: null, },
                      }
              console.log('pobieranie szyb');
          }
          this.state.window.door = door
          this.setDefaultGlass(door, 'defaultGlass')
          // this.setGlass(this.state.window) // zapis dopiero przy extra bo wymazuje mi localstorage
          this.setLoad('Oszklenie Drzwi')

          this.glassExtra()

      }).catch(error => {
          this.$store.commit("errors", 'App.vue, getIDWindows(18), ' + error);
      })
    },



    glassExtra() {
      AppService.getIDWindows(17).then( // oszklenie dostawki
        (response) => {
          var extra = {}
          var ListEGlass = new WindowsClass(response.data).getWindows()
          this.glassess.extra = ListEGlass 

          var firstEl = this.glassess.extra[0]
          var kindof = (parseInt(firstEl.protectInId) == 23 && parseInt(firstEl.protectOutId) == 110) ? this.kind.values[0] : this.kind.values[1]

                extra = { model: { id: this.conf.$KEY_ExtraWindow, name: firstEl.title, value: parseInt(firstEl.id), producent: firstEl.code, file: (firstEl.images[58] != null) ? firstEl.images[58] : firstEl.images[57], },
                          kind: { id: this.conf.$KEY_KindWindow, name: kindof.vname, value: parseInt(kindof.iid), },
                          type: { id: this.conf.$KEY_TypeWindow, name: firstEl.kindLabel, value: parseInt(firstEl.kind), },
                          textureWindow: { id: this.conf.$KEY_TextureWindow, name: firstEl.texture, value: parseInt(firstEl.textureId), },
                          in: { protectWindow: { id: this.conf.$KEY_ProtectWindowIn, name: firstEl.protectIn, value: parseInt(firstEl.protectInId),}, },
                          out: { protectWindow: { id: this.conf.$KEY_ProtectWindowOut, name: firstEl.protectOut, value: parseInt(firstEl.protectOutId), }, },
                          color: { id: 0, name: null, value: 0, color: null, text: null, file: null, },
                    }

          if (this._isExtra.status) {
            // console.log('leftExtra', Object.keys(this.$Accesories.window.leftExtra).toString());
              if (this._isExtra.localL) {
                extra = this.$Accesories.window.leftExtra
              } else if (this._isExtra.localR) {
                extra = this.$Accesories.window.rightExtra
              } else if (this._isExtra.localT) {
                extra = this.$Accesories.window.topExtra
              }

              this.sizeExtraWindow()
              // console.log('pobieranie dostawek', extra);
          }
          this.state.window.leftExtra = this._isExtra.left ? extra : this.$Accesories.window.leftExtra
          this.state.window.rightExtra = this._isExtra.right ? extra : this.$Accesories.window.rightExtra
          this.state.window.topExtra = this._isExtra.top ? extra : this.$Accesories.window.topExtra

          this.setDefaultGlass(extra, 'defaultExtraGlass')
          this.setGlass(this.state.window)
          this.setLoad('Oszklenie Dostawek')


          // this.getHandleAntabe()
          
      }).catch(error => {
          this.$store.commit("errors", 'App.vue, getIDWindows(17), ' + error);
      })

    },

    /* ustalanie wymiarów dostawek */
    sizeExtraWindow() {
      console.log('dorobić ładowanie do build left, right, top extra');

    },


    getHandleAntabe() {
      console.log('dorobić ustalenie domyslnej klamki lub antaby');

        AppService.getHandles().then(
        (response) => {
          this.Handles = new HandlesClass(response.data).getList()

              var toSelInside = null
              var toSelOutside = null

          AppService.getAntabes().then(
            (response) => {
              this.Antabes = new AntabesClass(response.data).getList()
              this.Products = this.Antabes.concat(this.Handles);

              for (const value of Object.values(this.Products)) {

                if (toSelOutside != null && toSelInside != null) {
                  // console.log('statefinal', this.selected[0].id, this.hand.outside.model.value, this.selected[0].selectHeight, this.selected[0].selectColor.id, this.hand.outside.height.value, this.hand.outside.color.value);
                  
                  this.toSave()
                  return false
                }

                if (toSelOutside == null && value.side.outside) {
                  var file1 = null
                  
                  /* state data outside */
                  if (this.hand.outside.model.value > 0) {
                    if (parseInt(this.hand.outside.model.value) === parseInt(value.id)) {
                      file1 = this.hand.outside.color.image //value.graphics.outside.name
                      toSelOutside = value
                      toSelOutside.selectHeight = this.hand.outside.height.value
                      toSelOutside.selectColor = {id: this.hand.outside.color.value, name: this.hand.outside.color.name, color: this.hand.outside.color.color, file: file1}
                      toSelOutside = value
                      this.selected[0] = toSelOutside
                    }
                  } else {
                    // console.log('else');
                    file1 = value.firstColor.image.name //value.graphics.outside.name
                    toSelOutside = value
                    toSelOutside.selectHeight = value.typ.id == 1 ? value.firstHeight : null
                    toSelOutside.selectColor = {id: value.firstColor.id, name: value.firstColor.title, color: value.firstColor.color, file: file1}
                    this.selected[0] = toSelOutside
                  }
                }


              //   if (toSelInside == null && value.side.inside) {
              //     var file2 = null
              //       /* state data inside */
              //       if (this.hand.inside.model.value > 0) {
              //         if (this.hand.inside.model.value == value.id) {
              //           file2 = this.hand.outside.color.image  //value.graphics.inside.name
              //           toSelInside = value
              //           toSelInside.selectHeight = value.typ.id == 1 ? this.hand.inside.height.value : null
              //           toSelInside.selectColor = {id: this.hand.inside.color.value, name: this.hand.inside.color.name, color: this.hand.inside.color.color, file: file2}
              //           this.selected[1] = toSelInside
              //         }
              //       }
              //       else if (!value.side.outside && value.typ.id == 2 && this.hand.inside.model.value == 0 && value.way[this.wayDoorName]) {
              //         file2 = (value.firstColor.image != null) ? value.firstColor.image.name : value.firstColor.image  //value.graphics.inside.name
              //         toSelInside = value
              //         toSelInside.selectHeight = value.typ.id == 1 ? value.firstHeight : null
              //         toSelInside.selectColor = {id: value.firstColor.id, name: value.firstColor.title, color: value.firstColor.color, file: file2}
              //         this.selected[1] = toSelInside
              //       }
                  
              //   }
              
              }

            }
          ).catch(error => {
            this.$store.commit("errors", 'Handles.vue, getAntabes(), ' + error);
          })

        }
      ).catch(error => {
        this.$store.commit("errors", 'Handles.vue, getHandles(), ' + error);
      })


      this.setLoad('Klamki')
    },

    /* end Nowe */


    updateState() {
      var s1 = {}

      this.$store.commit("renewConfig", { type: this.produktType }) // przepisanie z localStorage do store

      for (const [k1, v1] of Object.entries(this.$store.state.configurate)) {
        var s2 = {}

        for (const [k2, v2] of Object.entries(v1)) {
          var s3 = {}

          for (const k3 of Object.keys(v2)) {
            Object.assign(s3, { [k3]: Object.assign(v2[k3]) })
            // console.log(k3);
          }

          Object.assign(s2, { [k2]: Object.assign(s3) })
        }

        Object.assign(s1, { [k1]: Object.assign(s2) })
      }

      this.$store.commit("updateBuildConfig", Object.assign({ data: s1, state: this.produktType }));
    },


  },


  created() {

    /* Add settings for Cookies */
    this.$cookies.set("apps", {
      name: 'Konfigurator',
      ver: '1.0.0',
      author: 'a3m',
      web: 'https://a3m.pl', 
    });
    
    if (this.$cookies.isKey("session_account")) {
      this.$store.commit("updateAccount", Object.assign(this.$cookies.get('session_account')));
    }


    /* z localStorage do store - wazne */
    // console.log(JSON.parse(localStorage.getItem('helpers')))
    this.updateState()

    // console.log(JSON.parse(localStorage.getItem('helpers')))
    // console.log('XXXXXXXX',this.$store.state.configurate[this.produktType]);


    /* wzmocnienie szkła na zewnatrz */
    AppService.getProperty(67).then( 
      (response) => {
        this.kind = response.data
      }
    ).catch(error => {
      this.$store.commit("errors", 'App.vue, getProperty(67), ' + error);
    })


    /* Rozmiar domyślny drzwi */
    if (this.$Build.widthDoor.value == 0 || this.$Build.heightDoor.value == 0) {
        AppService.getSection(1359).then( 
          (response) => {
            var size = response.data.data[0]
            for (const vs of Object.values(size)) {
              if (vs.id == 6) {
                this.sizes.width = parseInt(vs.default)
                this.setSizes('widthDoor', {id: vs.id, name: vs.label, value: vs.default})
              }
              if (vs.id == 7) {
                this.sizes.height = parseInt(vs.default)
                this.setSizes('heightDoor', {id: vs.id, name: vs.label, value: vs.default})
              }
            }
          }
        ).catch(error => {
          this.$store.commit("errors", 'App.vue, getSection(1359), ' + error);
        })
    }


    /* Szerokość domyślna prawej dostawki */
    if (this._isExtra.right && this.$Build.rightExtra.value == 0) {
        AppService.getSection(1363).then( 
          (response) => {
            var size = response.data.data[0]
            for (const vs of Object.values(size)) {
              if (vs.id == 37) {
                this.sizes.extraRightWidth = parseInt(vs.default)
                this.setSizes('rightExtra', {id: vs.id, name: vs.label, value: vs.default})
              }
            }
          }
        ).catch(error => {
          this.$store.commit("errors", 'App.vue, getSection(1359), ' + error);
        })
    }

    /* Szerokość domyślna lewej dostawki */
    if (this._isExtra.left && this.$Build.leftExtra.value == 0) {
        AppService.getSection(1365).then( 
          (response) => {
            var size = response.data.data[0]
            for (const vs of Object.values(size)) {
              if (vs.id == 37) {
                this.sizes.extraLeftWidth = parseInt(vs.default)
                this.setSizes('leftExtra', {id: vs.id, name: vs.label, value: vs.default})
              }
            }
          }
        ).catch(error => {
          this.$store.commit("errors", 'App.vue, getSection(1359), ' + error);
        })
    }

    /* Wysokość domyślna górnej dostawki */
    if (this._isExtra.top && this.$Build.topExtra.value == 0) {
        AppService.getSection(1367).then( 
          (response) => {
            var size = response.data.data[0]
            for (const vs of Object.values(size)) {
              if (vs.id == 69) { 
                this.sizes.extraTopHeight = parseInt(vs.default)
                this.setSizes('topExtra', {id: vs.id, name: vs.label, value: vs.default})
              }
            }
          }
        ).catch(error => {
          this.$store.commit("errors", 'App.vue, getSection(1359), ' + error);
        })
    }




  
    /* Insert Data default Product */
    if (this._product > 0) {

      AppService.getIDDoor(this._product).then(
        (response) => {

          this.product1 = new DoorClass(response.data).getDoor()
          var request = { id: this.conf.$KEY_Product, 
                          name: this.product1.title, 
                          value: parseInt(this.product1.id),
                          producent: this.product1.code,
                          glass: this.product1.glass,
                          image: this.product1.graphics }

          this.$store.commit(
            "updateConfig", 
            Object.assign({ 
              type: 'door', 
              group: 'design', 
              element: 'product', 
              value: Object.assign(request) 
            })
          );
          this.setLoad('Produkty')


          /* panel strona wew/zew */
          var pIns = this.product1.panelInside
          var pInD = {
            id: this.conf.$KEY_Model, 
            value: pIns.special.status ? pIns.special.id : pIns.default.id,
            name: pIns.special.status ? pIns.special.name : pIns.default.name, }

          this.setModel('in', pInD)
          this.setModel('out', pInD)
          this.loading['Panele'] = true
          


          /* Kategoria produktu */
          this.$store.commit(
            "updateConfig", 
            Object.assign({ 
              type: 'door', 
              group: 'design', 
              element: 'category', 
              value: Object.assign({ 
                id: this.conf.$KEY_Category, 
                name: this.product1.kind_label, 
                value: this.product1.kind,
              }) 
            })
          );

          this.setLoad('Kategorie')


          /* Kolor zewnętrzny */
          this.setColor('outdoorColor', this.product1.firstColorDoor)
          this.setColor('indoorColor', this.product1.firstColorDoor)
          this.setColor('frameColor', this.product1.firstColorFrame)
          this.setLoad('Kolory')


          /* Oszklenie */
          this.glassWindow()
          
        }
      ).catch(error => {
        this.$store.commit("errors", 'App.vue, getIDDoor(' + this._product + '), ' + error);
      })
    }




  },

  computed: {

    _product() { // Product1
      if (this.$store.state.configurate[this.produktType].design.product.value > 0) {
        return this.$store.state.configurate[this.produktType].design.product.value
      }
      return this.ID
    },

    /* Czy drzwi są z szybą czy bez */
    _isGlassProd() { 
      return (Object.keys(this.product1.glass).length > 0) ? true : false
    },

    /* Czy jest dostawka i która */
    _isExtra() { 
      var data = {}
      var $KE = this.conf.$KEY_Extra
      var $AW = this.$Accesories.window
      var iL = ($KE.leftExtra.indexOf(this._BuildDoor.value) != -1) ?  true : false
      var iR = ($KE.rightExtra.indexOf(this._BuildDoor.value) != -1) ?  true : false
      var iT = ($KE.topExtra.indexOf(this._BuildDoor.value) != -1) ?  true : false
      var localL = (Object.keys($AW.leftExtra) != '') ?  true : false
      var localR = (Object.keys($AW.rightExtra) != '') ?  true : false
      var localT = (Object.keys($AW.topExtra) != '') ?  true : false

      Object.assign(data, {'status': (iL || iR || iT) ? true : false, 'left': iL, 'right': iR, 'top': iT, localL: localL, localR: localR, localT: localT})

      return data
    },

    $Design() {
      return this.$store.state.configurate[this.produktType].design
    },

    $Build() {
      return this.$store.state.configurate[this.produktType].build
    },

    $Color() {
      return this.$store.state.configurate[this.produktType].color
    },

    $Accesories() {
      if (typeof this.$store.state.configurate[this.produktType].accesories !== 'undefined') {
        return this.$store.state.configurate[this.produktType].accesories
      }
      return null
    },

    _BuildDoor() {
      return this.$store.state.configurate[this.produktType].build.buildDoor
    },

    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },

  }

}
</script>