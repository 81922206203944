import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'


var DomainPath = ''
    // DomainPath = '/lecada'
    // DomainPath = '/konfigurator'
const routes = [
  {
    path: DomainPath + '/',
    name: 'Home',
    id: 1,
    component: Home,
    // component: () => import('../views/Design.vue'),
    props: true,
    property: {
      navi: false,
    }
  },
   {
    path: DomainPath + '/design',
    name: 'Wzornictwo',
    id: 107,
    component: () => import('../views/Design.vue'),
    props: true,
    property: {
      navi: false,
      id: 107,
    }
  },
  {
    path: DomainPath + '/build',
    name: 'Konstrukcja',
    id: 108,
    component: () => import('../views/Build.vue'),
    props: true,
    property: {
      navi: false,
      id: 108,
    }
  },
  {
    path: DomainPath + '/color',
    name: 'Kolorystyka',
    id: 109,
    component: () => import('../views/Color.vue'),
    props: true,
    property: {
      navi: false,
      id: 109,
    }
  },
  {
    path: DomainPath + '/accessories',
    name: 'Akcesoria',
    id: 110,
    component: () => import('../views/Accessories.vue'),
    props: true,
    property: {
      id: 110,
    }
  },
  {
    path: DomainPath + '/nobarriers',
    name: 'Bez barier',
    id: 340,
    component: () => import('../views/Bariers.vue'),
    props: true,
    property: {
      id: 340,
    }
  },
  {
    path: DomainPath + '/order',
    name: 'Zamowienia',
    id: 112,
    component: () => import('../views/Order.vue'),
    props: true,
    property: {
      navi: false,
      id: 112,
    }
  },
  {
    path: DomainPath + '/account',
    name: 'Konto',
    id: 10,
    component: () => import('../views/Account.vue'),
    props: true,
    property: {
      counter: 1,
      action: 'login',
      navi: true,
    }
  },
  {
    path: DomainPath + '/account/logout',
    name: 'Wyloguj',
    id: 11,
    component: () => import('../views/Account.vue'),
    props: true,
    property: {
      counter: 4,
      action: 'logout',
      navi: true
    }
  },
  {
    path: DomainPath + '/list',
    name: 'Konfiguracje',
    id: 12,
    component: () => import('../views/List.vue'),
    props: true,
    property: {
      counter: 2,
      navi: true
    }
  },
  {
    path: DomainPath + '/orders',
    name: 'Lista Zamowienia',
    id: 13,
    component: () => import('../views/Orders.vue'),
    props: true,
    property: {
      counter: 3,
      navi: true
    }
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
