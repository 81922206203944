<template>
  <main>
    <div id="template">
      <router-view />
    </div>
    <Foot />
  </main>

  <aside>
    <div id="asidefixed">
      <header>
        <router-link to="/"><img src="@/assets/logo.svg" alt="Lecada" /></router-link>
        <span id="slogan">drzwi bez barier</span>
        <Navi />
      </header>
      <Menus />
      <Short />
      <Render />
      <!-- <Detail /> -->
    </div>
  </aside>
</template>

<script>
import Navi from '@/components/Navi.vue'
import Menus from '@/components/Menu.vue'
import Short from '@/components/Short.vue'
import Render from '@/components/Render.vue'
// import Detail from '@/components/Details.vue'
import Foot from '@/components/Footer.vue'




export default {
  name: 'Glowny-Index',
  components: {
    Navi,
    Menus,
    Short,
    Render,
    // Detail,
    Foot,
  },

  data() {
    return {}
  },

  created() {
    // zapis zmiennych $state (VUEX) do localStorage 
    // for (const [key, value] of Object.entries(this.$store.state.configurate)) {
      // localStorage.setItem(key, JSON.stringify(value))
    // }
  },

}
</script>