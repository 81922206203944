export default {
  $ADMIN: {
    user: '',
    password: ''
  },
  $PROD_MODE: 1,
  $LANG: 'pl',
  $API: 'https://lecada.com.pl/konfigurator/api/',
  $HOST: 'https://lecada.com.pl/konfigurator/',


  $KEY_Product: 131,
  $KEY_Product2: 131,
  $KEY_Model: 52,
  $KEY_Model2: 52,
  $KEY_Category: 2,
  $KEY_Category2: 2,
  
  /* Build */
  $KEY_TypeBuild: 53,
  $KEY_BuildPanel: 29,
  $KEY_BuildDoor: 29,
  $KEY_WidthDoor: 6,
  $KEY_HeightDoor: 7,
  $KEY_FillDoor: 4,
  $KEY_WayDoor: 3,
  $KEY_ViewDoor: 5,
  $KEY_LeftExtra: 37,
  $KEY_RightExtra: 37,
  $KEY_TopExtra: 69,

  $KEY_WidthPanelOut: 49,
  $KEY_HeightPanelOut: 48,
  $KEY_BignessPanelOut: 50,
  $KEY_WidthPanel2Out: 49,
  $KEY_HeightPanel2Out: 48,
  $KEY_BignessPanel2Out: 50,
  $KEY_WidthPanelIn: 49,
  $KEY_HeightPanelIn: 48,
  $KEY_BignessPanelIn: 50,
  $KEY_WidthPanel2In: 49,
  $KEY_HeightPanel2In: 48,
  $KEY_BignessPanel2In: 50,
  $KEY_WidthPanelW: 49,
  $KEY_HeightPanelW: 48,
  $KEY_BignessPanelW: 51,
  $KEY_WidthPanel2W: 49,
  $KEY_HeightPanel2W: 48,
  $KEY_BignessPanel2W: 51,

  /* Colors */
  $KEY_OutdoorColor: 8,
  $KEY_IndoorColor: 8,
  $KEY_FrameColor: 9,
  $KEY_OutdoorColorPanel1: 0,
  $KEY_IndoorColorPanel1: 0,
  $KEY_OutdoorColorPanel2: 0,
  $KEY_IndoorColorPanel2: 0,


  /* Hand Accesoriess */
  $KEY_HandModel: 0,
  $KEY_HandType: 14,
  $KEY_HandHeight: 15,
  $KEY_HandSection: 16,
  $KEY_HandCrossWidth: 17,
  $KEY_HandCrossHeight: 18,
  $KEY_HandColor: 0,
  $KEY_HandSide: 70,
  $KEY_HandWay: 33, 

  /* Window Accesoriess */
  $KEY_WindowModel: 0,
  $KEY_ExtraWindow: 0,
  $KEY_TextureDoor: 11,
  $KEY_KindWindow: 67,
  $KEY_TypeWindow: 10,
  $KEY_TextureWindow: 11,
  $KEY_ProtectWindowIn: 12,
  $KEY_ProtectWindowOut: 13,

  /* Kick Accesoriess */
  $KEY_KickModel: 0,
  $KEY_KickSize: 23,
  $KEY_KickColor: 0,

  /* Rosette Accesoriess */
  $KEY_RosetteModel: 0,
  $KEY_RosetteType: 21,
  $KEY_RosetteColor: 0,

  /* Controls Accesoriess */
  $KEY_ControlsModel: 0,
  $KEY_ControlType: 40,

  /* Bariers */
  $KEY_BarierStatus: 68,
  $KEY_BarierDefault: 61,
  $KEY_BarierExtend: 62,




  $KEY_Extra: {
    topExtra: [47, 48, 49, 50],
    rightExtra: [44, 46, 48, 50],
    leftExtra: [45, 46, 49, 50],
  },


  $KEY_Render: {
    skala: 3.5,
    rama: {
      door: 100,
      panel: 0,
      panel2: 0
    }
  },

  $KEY_WayDoorName: {
    8: 'left',
    52: 'left',
    7: 'right',
    51: 'right'
  },

  $KEY_ViewName: {
    13: 'outside',
    14: 'inside',
  },

  $KEY_ViewDoorStat: {
    0: {id: 12, name: 'od zewnątrz' },
    1: {id: 11, name: 'od wewnątrz' },
  },
  $KEY_ViewDoorRevers: {
    12: {id: 0, name: 'od zewnątrz' },
    11: {id: 1, name: 'od wewnątrz' },
  },

};