import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'
import App from './App.vue'
import router from './router'
import store from './store'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "./assets/css/style.css"
import "./assets/css/components.css"
import "./assets/css/mods.css"
import "./assets/css/render.css"

const app = createApp(App);
app.use(router)
   .use(store)
   .use(require('vue-cookies'), { expires: '7d', sameSite: 'none', secure: true})
   // .use(require('bootstrap'))
   .use(VueLazyLoad, {delay: 50})
   .mount('#app')
