<template>
  <nav id="menu" class="nav">
    <div v-for="nav in menu" v-bind:key="nav"> 
    <router-link class="nav-link" :class="{active: nav.path==$route.path}" :to="nav.path" v-if="nav.path">
    {{ nav.counter }}. {{ nav.title }}
    </router-link>
    </div>
  </nav>
</template>

<script>
import MenuClass from '@/classes/MenuClass';
import AppService from '@/services/app.service';

export default {
  name: 'Glowne-Menu',

  data() {
    return {
      menu: {}
    }
  },
  
  created() {
    /* Menu */
    AppService.getSteps().then(
      (response) => {
        var MyMenu = new MenuClass(response.data, this.$router);
        this.menu = MyMenu.getMenu()
        localStorage.setItem('menu', JSON.stringify(this.menu))
        // this.countMenuElements();
      }
    ).catch(error => {
      this.$store.commit("errors", 'Menu.vue, getSteps(), ' + error);
    })
    
  },

}
</script>
