export default class RangeClass {
    
    Data = [];
    Menu = {};
 
    constructor(data) 
    {
      this.Data = data[0]
    }

    setPath(data)
    {
      var items = []
      data.forEach((element) => {
        var j = []
        var iter = 0;
        for (const [key, value] of Object.entries(element.value)) {
          j.push(Object.assign({ 
            item: iter,
            key: parseInt(key), 
            title: value 
          }))
          iter++;
        }

        var i = {
          id: element.id,
          label: element.label,
          // counter: element.counter,
          // type: element.radio,
          value: j,
        }
        items.push(i);
        
      })
      return items
    }

    sort(data) 
    {
      return data.sort((a, b) => {
        return a.counter - b.counter
      })
    }

    getOption() 
    {
      var nMenu = this.setPath(this.Data)
      this.Menu = this.sort(nMenu)

      return this.Menu
    }
}