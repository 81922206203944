<template>
  <footer>
    <div id="company">Copyright © {{currentYear}} Lecada</div>
    <div id="author">Developed with passion A3M</div>
  </footer>
</template>

<script>
export default {
  name: 'Stopka-Footer',
  data() {
    return {}
  },
  
  computed: {
    currentYear() {
      return new Date().getFullYear()
    }
  }
}
</script>