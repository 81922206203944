<template>
  <div id="loader">
    <img src="@/assets/loader.svg" width="60">
    Trwa ładowanie:
    <div class="module">{{ data }}</div>
  </div>
</template>
<script>
export default {
  name: 'Loader-Anim',
  props: {
    data: String
  },
}
</script>