<template>
  <div class="range">
    <div class="range-block">
      <button v-for="item in elements" v-bind:key="item.key" @click="handleClick(item.key, item.title)" 
        :class="{ active: item.key == value }" class="range-item">
        {{ item.title }}
      </button>
    </div>
  </div>
</template>

<script>
import AppService from '@/services/app.service';

export default {
  name: 'kontrolka-range',
  props: {
    data: {
      type: Array
    },
    id: {
      type: String
    },
  },

  data() {
    return {
      elements: this.data,
      header3: '',
      header4: '',
      header5: ''
    }
  },

  methods: {
    handleClick(value, name) {
      if (this.id == 3) { // kierunek drzwi
        this.$store.commit(
          "updateConfig", 
          Object.assign(
            { 
              type: this.produktType, 
              group: 'build', 
              element: 'wayDoor', 
              value: Object.assign(
                { 
                  id: 3, 
                  name: name, 
                  value: parseInt(value) 
                }
              ) 
            }
          )
        )

        this.$store.commit(
          "updateHelper", 
          Object.assign({ 
              element: 'showWay', 
              value: parseInt(value) 
          })
        )
      }

      if (this.id == 4) { // wypełnienie drzwi
        this.$store.commit(
          "updateConfig", 
          Object.assign(
            { 
              type: this.produktType, 
              group: 'build', 
              element: 'fillDoor', 
              value: Object.assign(
                { 
                  id: 4, 
                  name: name, 
                  value: parseInt(value) 
                }
              ) 
            }
          )
        );
      }

      if (this.id == 5) { // wypełnienie drzwi
        this.$store.commit(
          "updateConfig", 
          Object.assign({ 
              type: this.produktType, 
              group: 'build', 
              element: 'viewDoor', 
              value: Object.assign({ 
                  id: 5, 
                  name: name, 
                  value: parseInt(value) 
              }) 
            })
        );

        this.$store.commit(
          "updateHelper", 
          Object.assign({ 
              element: 'showView', 
              value: parseInt(value) 
          })
        )
      }
    },



  },

  created() { 
    
    AppService.getProperty(3).then( // kierunek drzwi
      (response) => {
        this.header3 = response.data.title
      }
    ).catch(error => {
      this.$store.commit("errors", 'Range.vue, getProperty(3), ' + error);
    })

    AppService.getProperty(4).then( // wypelnienie
      (response) => {
        this.header4 = response.data.title
      }
    ).catch(error => {
      this.$store.commit("errors", 'Range.vue, getProperty(4), ' + error);
    })

    AppService.getProperty(5).then( // widok drzwi
      (response) => {
        this.header5 = response.data.title
      }
    ).catch(error => {
      this.$store.commit("errors", 'Range.vue, getProperty(5), ' + error);
    })

  },

  computed: {
    value() {

      var element = null
      var name = null
      var val = 0

      if (this.id == 3) {
        element = 'wayDoor'
        if (this.$store.state.configurate[this.produktType].build.wayDoor.value > 0) {
          for (const value3 of Object.values(this.elements)) {
           
            if (value3.key == this.$store.state.configurate[this.produktType].build.wayDoor.value) {
              name = value3.title
              val = value3.key 

              this.$store.commit(
              "updateHelper", Object.assign({ 
                  element: 'showWay', 
                  value: parseInt(value3.key ) }))
            }
          }
        } else {
          val = Object.values(this.elements[0])[1]
        }
      }

      if (this.id == 4) {
        element = 'fillDoor'
        if (this.$store.state.configurate[this.produktType].build.fillDoor.value > 0) {
          for (const value4 of Object.values(this.elements)) {
            if (value4.key == this.$store.state.configurate[this.produktType].build.fillDoor.value) {
              name = value4.title
              val = value4.key 
            }
          }
        } else {
          val = Object.values(this.elements[0])[1]
        }
      }

      if (this.id == 5) {
        element = 'viewDoor'
        if (this.$store.state.configurate[this.produktType].build.viewDoor.value > 0) {
          for (const value5 of Object.values(this.elements)) {
            if (value5.key == this.$store.state.configurate[this.produktType].build.viewDoor.value) {
              name = value5.title
              val = value5.key 

              this.$store.commit(
              "updateHelper", Object.assign({ 
                  element: 'showView', 
                  value: parseInt(value5.key ) }))
            }
          }
        } else {
          val = Object.values(this.elements[0])[1]
        }

        

      }

      this.$store.commit("updateConfig", Object.assign({ type: this.produktType, group: 'build', element: element, value: Object.assign({ id: this.id, name: name, value: val }) }));
      return val
    },


    produktType() {
      switch(this.$store.state.helpers.typeBuild) {
        case 76: return 'panel'; 
        case 77: return 'door'; 
        default: return 'door'; 
      }
    },
  },


}
</script>
