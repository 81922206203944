export default class HandlesClass {

  List = [];

  constructor(data) 
  {
    this.List = data
  }

  
  isObjectEmpty(objectName) {
    return Object.keys(objectName).length === 0
  }


  newObj(data, keys) {
    var item = {}

    for (const [k1, v1] of Object.entries(data)) {
      var name = keys[k1]
      Object.assign(item, { 
        [name]: Object.assign({ id: parseInt(k1), name: v1 }) 
      })
    }
    return item
  }


  setList(data)
  {
    var handles = []
    var coinsList = ['cost']
    var numberList = ['id', 'kind', 'counter']
    var breakList = ['alias', 'odkiedy', 'handdate', 'desc', 'sdesc']
    var stringList = ['code', 'fulltitle', 'title', 'subtitle', 'kind_label', 'image']
    var arrayList = ['attribs', 'graphics', 'colors', 'codes']
    // console.log(data);
    
    data.forEach((element) => {
      var handle = {}

      // bo musi być okreslona strona
      Object.assign(handle, Object.assign({'side': {inside: false, outside: false }}))

      Object.assign(handle,  Object.assign({'typ': {id: 2, name: 'handle'}}))
      for (let [key, value] of Object.entries(element)) {
        
        if (breakList.indexOf(key) == -1) {

          /* coins */
          if (coinsList.indexOf(key) > -1) {
            Object.assign(handle,  Object.assign({[key]: value}))
          }

          /* strings */
          if (stringList.indexOf(key) > -1) {
            Object.assign(handle,  Object.assign({[key]: value}))
          }

          /* numbers */
          if (numberList.indexOf(key) > -1) {
            Object.assign(handle, Object.assign({[key]: parseInt(value)}))
          }

          /* arrays */
          if (arrayList.indexOf(key) > -1) {

            if (key == 'codes') {
              var codes = {}
              
              for(let [k, v] of Object.entries(value)) {
                Object.assign(codes, {[k]: {id: parseInt(k), name: v}})
              }
              Object.assign(handle, Object.assign({[key]: Object.assign(codes)}))
            }

            if (key == 'graphics') {
              value = this.newObj(value, Object.assign({42:'outside', 41:'inside'}))
              Object.assign(handle, Object.assign({[key]: value}))
            }

            // if (key == 'colors') {
            //   Object.assign(handle, Object.assign({[key]: value.door}))
            // }

            if (key == 'colors') {
              var first = {}
              var group = {}
               for (let [k1, v1] of Object.entries(value.door)) {

                var colors = {}
                for (let [k2, v2] of Object.entries(v1)) {
                  var color = Object.assign(v2, {id: parseInt(k2)})

                  for (let [k3, v3] of Object.entries(color)) {

                    /* image */
                    if (k3 == 'image') {
                      if (v3 !== null) {
                        color.image = {name: Object.values(v3).toString(), id: parseInt(Object.keys(v3))}
                      }
                    }

                    /* typ */
                     if (k3 == 'typ') {
                      if (v3 !== null) {
                        color.typ = {name: Object.values(v3).toString(), id: parseInt(Object.keys(v3))}
                      }
                    }

                  } 

                  if (this.isObjectEmpty(first)) {
                    Object.assign(first, color)
                  }
                  Object.assign(colors, Object.assign({[k2]: color}))
                }
                Object.assign(group, {[k1]: Object.assign(colors)})
              }
              Object.assign(handle, Object.assign({'firstColor': first}))
              Object.assign(handle, Object.assign({[key]: group}))
            }

            if (key == 'attribs') {

              if (typeof value[70] !== 'undefined') { // strona montazu
                handle.side = {
                  inside: {status: false,},
                  outside: {status: false,}
                }
                for (let [k,v] of Object.entries(value[70])) {
                  if (parseInt(k) == 112) { handle.side.inside = {status: true, id: k, name: v} }
                  if (parseInt(k) == 111) { handle.side.outside = {status: true, id: k, name: v} }
                }
              }

              if (typeof value[33] !== 'undefined' ) { // strona montazu
                var way = this.newObj(value[33], Object.assign({53:'right', 54:'left'})) 
                Object.assign(handle, Object.assign({'way': way}))
              }

            }
          }

        }
      }
      
      handles.push(Object.assign(handle))
      
    })

    return handles;
  }


  sort(data) 
  {
    return data.sort((a, b) => {
      return a.counter - b.counter
    })
  }

  getList() 
  {
    return this.setList(this.List)
  }

}